import React, { useCallback, useEffect } from 'react';
import { DatePicker, TimePicker, Row, Col } from 'antd';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import styled from 'styled-components';

import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

interface Props {
  value: string | object;
  onChange: (date: Moment) => void;
  onBlur?: (e: React.SyntheticEvent<Element, Event>) => void;
  disabled?: boolean;
  datePlaceholder?: string;
  timePlaceholder?: string;
  style?: React.CSSProperties;
  isCustomOutupt?: boolean;
  // isCustomOutupt - Used for custom output as of Nov 2024 this is used specifically for the ScheduleTasks page
  // This Component is used for other pages as well, this variables will isolate the custom output of date requirements of the ScheduleTasks page
  // while retaining the original output of the other pages, for now this does not need for other pages since unknown if their output is good.
  // If time comes that other pages needed update on their time output this can be use as well with extensive proper testing
}

const DateTimeField = ({
  value,
  onChange,
  disabled = false,
  datePlaceholder = 'DD MMM YYYY',
  timePlaceholder = 'HH:mm',
  style,
  onBlur,
  isCustomOutupt
}: Props) => {
  const onDateChange = (value, _) => {
    if (value) {
      const newValue = value.set({
        day: value.get('day'),
        month: value.get('month'),
        year: value.get('year')
      });
      onChange(moment(newValue).milliseconds(0));
    } else {
      onChange(undefined);
    }
  };

  const onTimeChange = (value, _) => {
    if (value) {
      const newValue = value.set({
        hour: value.get('hour'),
        minute: value.get('minute'),
        second: 0
      });
      onChange(moment(newValue).milliseconds(0));
    } else {
      onChange(undefined);
    }
  };

  const getTimeValue = useCallback(
    (timeValue: string | object) => {
      if (typeof timeValue === 'string' && isCustomOutupt) {
        // 2025-10-12T 12:00:00.000Z
        const [datePart, timePart] = timeValue.includes(' ')
          ? timeValue.split(' ')
          : timeValue.split('T');
        const [hour, minute] = timePart.split(':');
        const updatedTime = `${hour}:${minute}`;
        return moment(`${datePart}T${updatedTime}`, moment.ISO_8601, true); // new value return from custom output
      }
      return value && moment(value); // old value return
    },
    [value, isCustomOutupt]
  );

  return (
    <Row gutter={4} style={style}>
      <Col span={12}>
        <StyledDatePicker
          disabled={disabled}
          showTime={false}
          value={value && moment(value)}
          placeholder={datePlaceholder}
          onChange={onDateChange}
          onBlur={onBlur}
          format={DATE_FORMAT}
        />
      </Col>
      <Col span={12}>
        <StyledTimePicker
          disabled={disabled}
          value={getTimeValue(value)}
          placeholder={timePlaceholder}
          onChange={onTimeChange}
          format={TIME_FORMAT}
        />
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  &&& {
    width: 100%;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  &&& {
    width: 100%;
  }
`;

export default DateTimeField;
